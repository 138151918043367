import axios from 'axios'
import Link from 'next/link'
import React, { ReactNode, useCallback, useState } from 'react'
import toast from 'react-hot-toast'

import { IntoUrl } from '../models/IntoUrl'
import MixMix from '../services/MixMix'
import DynamicReactJsonView from './DynamicReactJsonView'
import Modal from './Modal'

export const UrlDebugInfo = (props: {
  url: IntoUrl
  buttonClassName: string
  children: ReactNode
  onClick?: (e: React.MouseEvent) => void
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [isHidden, setIsHidden] = useState(false)

  const toggleHide = useCallback(() => {
    void toast
      .promise(axios.request(MixMix.admin.url.moderate(props.url.url_id, isHidden ? 'UNBAN' : 'SET_HIDDEN')), {
        loading: isHidden ? 'Unhiding...' : 'Hiding...',
        success: isHidden ? 'Not Hidden' : 'Hidden',
        error: error => `Failed to change hidden. Error: ${error.message}`,
      })
      .then(() => setIsHidden(h => !h))
  }, [isHidden, props.url.url_id])
  return (
    <>
      <button
        title="Debug info"
        className={props.buttonClassName}
        onClick={e => {
          e.preventDefault()
          props.onClick?.(e)
          setShowInfoModal(o => !o)
        }}
      >
        {props.children}
      </button>
      {showInfoModal && (
        <Modal onClose={() => setShowInfoModal(false)} isVisible={showInfoModal}>
          <div className="relative z-10 m-12 flex max-h-screen w-screen flex-col space-y-6 bg-contrast p-6 text-black sm:w-[56rem]">
            <DynamicReactJsonView
              src={props.url}
              name={false}
              displayDataTypes={false}
              iconStyle={'triangle'}
              collapseStringsAfterLength={60}
              collapsed={2}
              theme={'tomorrow'}
              style={{ height: '24rem', overflow: 'scroll', padding: '1rem' }}
            />
            <div className="grid grid-cols-2 gap-6 sm:flex sm:flex-row">
              <Link className="btn btn-secondary" target="_blank" href={`/admin/url/${props.url.url_id}`}>
                View in Admin panel
              </Link>
              <Link
                className="btn btn-secondary"
                target="_blank"
                href={`https://mb.mix.com/dashboard/112?url_id=${props.url.url_id}`}
              >
                Info Dashboard
              </Link>
              <button className="btn btn-secondary" onClick={toggleHide}>
                {isHidden ? 'Unhide' : 'Hide'}
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  void toast.promise(axios.request(MixMix.admin.url.moderate(props.url.url_id, 'REINGEST')), {
                    loading: 'Re-ingesting...',
                    success: 'Successfully Re-ingested',
                    error: e => `Failed to reingest. Error: ${e.message}`,
                  })
                }}
              >
                Re-ingest
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
