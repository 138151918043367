import { APIRequest } from '../../hooks/useRequest'
import { addEvent } from '../../lib/LocalEventStorage'
import logger from '../../lib/logger'
import { commands } from '../../lib/messages/protobuf'
import { addBusinessEvent } from '../../redux/slices/businessEventsSlice'
import { store } from '../../redux/store/store'
import { postMessageToServiceWorker } from '../communicator/worker'
import { encodeMessage } from '../utils/protobuf'
import { ISerializableMessageWithContext, processAxiosInterceptors } from './broadcastHelpers'
import CommandContext = commands.CommandContext

export type Commands = keyof typeof commands

export const broadcastTrackingMessage = async (
  command: Commands,
  initialConfig: APIRequest,
  createMessage: (context: CommandContext) => ISerializableMessageWithContext
) => {
  const config = processAxiosInterceptors(initialConfig)
  const context = config.data?.context ?? new CommandContext()
  // Create a message object using the protobuf function. We must pass in the context object created by the axios interceptors.
  const message = createMessage(context)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((message as any).userId) {
    const serializedConfig = { ...config, data: message.toJSON() }
    logger.info(`${command} Data:`, serializedConfig.data)
    await postMessageToServiceWorker(command, serializedConfig)

    // Add business event
    store.dispatch(
      addBusinessEvent({
        type: 'request',
        command,
        url: config.url || '',
        method: config.method || '',
        data: serializedConfig.data,
        timestamp: Date.now(),
      })
    )
  } else {
    const encodedMessage = encodeMessage(command, message)
    addEvent(command, encodedMessage)
    logger.info(`Stored ${command} event locally`)
  }
}
