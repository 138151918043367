import toSafeInteger from 'lodash.tosafeinteger'
import Long from 'long'
import { useCallback, useEffect, useState } from 'react'
import { useImmer } from 'use-immer'
import { useDebounce } from 'usehooks-ts'

import { useCurrentUserContext } from '../../context/CurrentUserContext'
import logger from '../../lib/logger'
import { commands } from '../../lib/messages/protobuf'
import MixMix from '../../services/MixMix'
import { broadcastTrackingMessage } from '../broadcast/trackingMessage'

import TrackGridViews = commands.TrackGridViews

import GridView = commands.TrackGridViews.GridView

type SerializedItem = `${number}|${string}`

const TRACK_GRIDVIEWS_DEBOUNCE = 300

export const useTrackGridViews: (debounce?: number) => (urlID: string, position: number, isInView: boolean) => void = (
  debounce: number = TRACK_GRIDVIEWS_DEBOUNCE
) => {
  const [visibleItems, setVisibleItems] = useImmer<Set<SerializedItem>>(new Set())
  const [trackedItems, setTrackedItems] = useImmer<Set<SerializedItem>>(new Set())

  //#region Collector – A function that accepts grid views as they become visible and stores into the visibleItems set.
  const onViewGridItem = useCallback(
    (urlID: string, position: number, isInView: boolean) => {
      const gridView: SerializedItem = `${position}|${urlID}`
      if (!isInView || trackedItems.has(gridView)) return
      setVisibleItems(draft => draft.add(gridView))
    },
    [setVisibleItems, trackedItems]
  )
  //#endregion

  //#region Generator – uses debounce to listen to visibleItems and generates the grid views to be tracked.
  const visibleItemsDebounced = useDebounce(visibleItems, debounce)
  const { currentUserID: userId } = useCurrentUserContext()
  const [gridViewsToTrack, setGridViewsToTrack] = useState<GridView[]>([])
  useEffect(() => {
    if (!userId || visibleItemsDebounced.size === 0) return
    const gridViews: GridView[] = []
    visibleItemsDebounced.forEach(item => {
      if (trackedItems.has(item)) return
      const [position, urlID] = item.split('|')
      gridViews.push(new GridView({ urlId: Long.fromString(urlID), position: toSafeInteger(position) }))
    })
    setGridViewsToTrack(gridViews)
    setTrackedItems(draft => visibleItemsDebounced.forEach(draft.add.bind(draft)))
    setVisibleItems(new Set())
  }, [setGridViewsToTrack, setTrackedItems, setVisibleItems, trackedItems, userId, visibleItemsDebounced])
  //#endregion

  //#region Tracker – Track grid views as the Generator generates them.
  useEffect(() => {
    if (!userId || gridViewsToTrack?.length === 0) return
    const createMessage = (context: commands.CommandContext) => {
      return new TrackGridViews({
        gridViews: gridViewsToTrack,
        context,
        userId,
      })
    }

    const initialConfig = MixMix.commands.trackGridViews({})
    void broadcastTrackingMessage('TrackGridViews', initialConfig, createMessage).catch(err =>
      logger.warn('Failed to track grid views', err)
    )
  }, [gridViewsToTrack, userId])
  //#endregion

  // Return the collector
  return onViewGridItem
}
