import NextImage from 'next/image'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { useGlobalContext } from '../context/GlobalContexts'
import { useTrackClick } from '../hooks/analytics/useTrackClick'
import IconPlay from '../icons/IconPlay.svg'
import IconSend from '../icons/IconSend.svg'
import UrlHelper from '../lib/UrlHelper'
import { IntoUrl } from '../models/IntoUrl'
import { selectShowDebugInfo } from '../redux/slices/tweaksSlice'
import { useAppSelector } from '../redux/store/store'
import { ProgressiveImageWithManagedState } from './ProgressiveImage'
import { UrlDebugInfo } from './UrlDebugInfo'

export const UrlGridItemLoading = ({ height }: { height: 'aspect-square' | string }) => {
  return (
    <div className="overflow-hidden rounded">
      <span className={`block w-full animate-pulse bg-contrast/5 ${height}`}></span>
    </div>
  )
}

const FALLBACK_GRID_SIZE = { width: 250, height: 300 }

interface UrlGridItemProps {
  url: IntoUrl
  index: number
  layout: 'masonry' | 'grid'
  maxImageWidth: number
  isVideoAutoplayEnabled?: boolean
  isTitleEnabled?: boolean
  likedByUsername?: string
  alwaysShowDebugInfo?: boolean
  onClick?: () => void
  onClickSideEffect?: (isMetaKeyPressed: boolean) => void
  onVisibilityChanged?: (inView: boolean) => void
}

export const UrlGridItem = ({
  url,
  index,
  layout,
  maxImageWidth,
  isTitleEnabled,
  likedByUsername,
  alwaysShowDebugInfo,
  onClick,
  onClickSideEffect,
  onVisibilityChanged,
}: UrlGridItemProps) => {
  const { ref, inView } = useInView({ threshold: 0.5, onChange: onVisibilityChanged })
  const trackClick = useTrackClick()
  const clickHandler = (event: React.MouseEvent) => {
    trackClick('urlItem')
    onClickSideEffect?.(event.metaKey)
    if (onClick && !event.metaKey) {
      event.preventDefault()
      onClick()
    }
  }

  const showDebugInfo = useAppSelector(selectShowDebugInfo)
  const layoutClassName = layout === 'grid' ? 'aspect-square' : 'h-full'
  const {
    browserInfo: {
      ios,
      browser: { safari },
    },
  } = useGlobalContext()
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined)

  useEffect(() => {
    const hlsUrl = url.meta.media?.[0]?.hls_url
    const mp4Url = url.meta.media?.[0]?.mp4_url
    if (ios || safari) setVideoUrl(hlsUrl ?? mp4Url)
    else setVideoUrl(mp4Url)
  }, [url.meta.media, ios, safari])
  const isAutoplayEnabled = useAppSelector(state => state.feed.isAutoplayEnabled)
  const showPlayButton =
    !isAutoplayEnabled && (!!videoUrl || url.meta.mediaType === 'VIDEO' || url.meta.mediaType === 'GIF')

  const resizedThumb = ((): string | undefined => {
    const thumb = url?.thumbnail?.replace(/w_\d+/, `w_${maxImageWidth}`)
    if (layout === 'grid') return thumb?.replace(/h_\d+/, `h_${maxImageWidth}`)
    return thumb
  })()

  return (
    <div className="relative">
      <Link
        ref={ref}
        href={UrlHelper.urlIDPath({
          urlID: url.url_id,
          likedBy: likedByUsername,
          recId: url.meta.recId,
          recBatchId: url.meta.recBatchId,
        })}
        className={[
          'group block h-fit scale-100 overflow-hidden bg-transparent transition-all duration-100 ease-in',
          onClick ? 'cursor-zoom-in' : 'cursor-pointer',
        ].join(' ')}
        onClick={clickHandler}
      >
        <div className="relative flex justify-center bg-primary/30">
          <ProgressiveImageWithManagedState
            className={['block max-h-[80vh] w-full rounded object-cover sm:max-h-[60vh]', layoutClassName].join(' ')}
            src={resizedThumb ?? '/GrayTile.svg'}
            alt={url.title}
            blurPlaceholder={url.tiny_thumbnail || url?.thumbnail || ''}
            contentSize={{
              width: url.meta.contentSize?.width ?? url.meta.media?.[0]?.width ?? FALLBACK_GRID_SIZE.width,
              height: url.meta.contentSize?.height ?? url.meta.media?.[0]?.height ?? FALLBACK_GRID_SIZE.height,
            }}
          />
          {videoUrl && (
            <video
              hidden={!inView}
              className={[
                'absolute bottom-0 left-0 right-0 top-0 w-full flex-1 rounded object-cover',
                layoutClassName,
                isAutoplayEnabled ? '' : 'hidden group-hover:block',
              ].join(' ')}
              src={videoUrl}
              poster={url?.thumbnail}
              autoPlay
              muted
              loop
              playsInline
            />
          )}
          {url.shares?.length && url.shares[0].sender && (
            <div className="absolute h-auto w-full bg-gradient-to-b from-black/50 to-transparent p-3">
              <div className="relative size-11">
                <NextImage
                  className="size-full rounded-full border-[1.5px] border-accent"
                  src={url.shares[0].sender.photo_url ?? ''}
                  alt={url.shares[0].sender.display_name}
                  width={1}
                  height={1}
                />
                <IconSend className="absolute bottom-0 right-0" />
              </div>
            </div>
          )}
          {showPlayButton ? (
            <IconPlay className="absolute z-10 size-12 max-h-[40%] max-w-[40%] self-center opacity-100 transition-opacity duration-200 group-hover:opacity-0 sm:size-16 md:size-20 4xl:size-32" />
          ) : (
            <></>
          )}
        </div>
        {isTitleEnabled ? (
          <div className="absolute bottom-0 z-20 line-clamp-3 w-full bg-gradient-to-b from-transparent to-black/80 px-3 py-4 text-sm font-semibold leading-tight text-white opacity-0 shadow-black drop-shadow-sm transition-all group-hover:opacity-100">
            {url.title}
          </div>
        ) : (
          <></>
        )}
      </Link>
      {(showDebugInfo || alwaysShowDebugInfo) && (
        <UrlDebugInfo
          buttonClassName="absolute right-3 top-3 z-20 flex items-center justify-center rounded-full bg-accent/30 px-2 py-1 font-mono text-sm hover:bg-accent"
          url={url}
        >
          {index}
        </UrlDebugInfo>
      )}
    </div>
  )
}
